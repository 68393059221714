import React from 'react'
import { Carousel } from "flowbite-react";
import { useState, useEffect } from 'react';


function SubFooter() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "/images/slide-1.png",
    "/images/slide-2.png",
    "/images/slide-3.png",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change the duration according to your preference

    return () => clearInterval(interval);
  }, []); // Empty dependency array to run this effect only once on component mount
  return (
    <div className='relative'>
        {/* <img className='bottom-0 left-0 absolute z-0 max-h-[380px]' src="/images/blue.png"/> */}
    <div className='flex  flex-col px-4 lg:pr-4 xl:flex-row items-center justify-between pt-6 lg:pt-0 mx-auto ml-0'>
    {/* <div className='flex max-w-[1300px] mx-auto flex-col-reverse px-4 xl:flex-row justify-between pt-[80px] mx-auto'> */}
    <img className=' xl:max-w-[50%] relative z-10' src={images[currentImageIndex]}/>
        <div className='flex flex-col  item-center space-y-[40px] pb-[80px]'>
            <h6 className='text-[40px] lg:max-w-[530px] 2xl:text-[40px] text-black font-extrabold text-center'>Make your Party awesome with Event Photo Stations</h6>
            <p className='text-[16px] max-w-[500px] 2xl:text-[20px] text-black/70 font-medium text-center'>An instant photo station experience To help your event be amazing.</p>
            <div className='flex flex-row items-center justify-center mx-auto gap-x-[20px]'>
            <a href="#contact" className='bg-[#4BB0E2] rounded-[8px] px-4 2xl:w-[228px] h-[55px] flex  items-center justify-center text-white flex flex-row gap-x-2 items-center'><img className='w-[25px]' src="/images/reserve-icon.png"/>Reserve Now</a>
            </div>
        </div>
    </div>
</div>
  )
}

export default SubFooter