"use client";

import React from 'react'


function Review() {
  return (
    <div className=' min-h-[500px] max-w-screen  bg-no-repeat py-[80px] bg-cover flex flex-col items-center justify-center' style={{background: 'url(/images/how2.png)', backgroundSize: 'cover'}}>
        <div className=' max-w-screen flex flex-col xl:flex-row items-center gap-y-[40px] lg:gap-[40px] '>
            <div className='flex max-w-[100vw] flex-col   items-center xl:items-start gap-y-[36px]'>
                <h6 className='text-white text-[36px] lg:text-[40px] font-extrabold mb-[24px]'>See what people are saying</h6>
                <div className='flex flex-col items-center lg:items-start gap-y-2 x lg:border-l-2 lg:max-w-full max-w-[90%] border-l-white lg:pl-9'>
                    <p className='text-[14px] lg:text-[16px] text-center lg:text-left font-medium text-white max-w-full lg:max-w-[400px]'>“I think the best thing about it is the accessibility and ease of use”</p>
                    <p className='text-[12px] lg:text-[14px] font-medium text-white/50'>- Chrissie Blatt, event planner</p>
                </div>
                <div className='flex flex-col items-center lg:items-start gap-y-2 x lg:border-l-2 lg:max-w-full max-w-[90%] border-l-white lg:pl-9'>
                    <p className='text-[14px] lg:text-[16px] text-center lg:text-left font-medium text-white max-w-full lg:max-w-[400px]'>“They are super efficient and they are very professional”</p>
                    <p className='text-[12px] lg:text-[14px] font-medium text-white/50'>-- Becky Noth, TQL</p>
                </div>
            </div>
            {/* CAROUSEL.. */}
            {/* <div className='lg:w-[538px] w-[320px]  h-[200px]  lg:h-[308px] rounded-[8px] bg-white'></div> */}
            <video className='max-w-[538px]' controls autoplay type="video/mp4" src='/images/testimonial.mp4'></video>
        </div>

    </div>
  )
}

export default Review