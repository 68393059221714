import logo from './logo.svg';
import './App.css';
import { Nav } from './components/Nav';
import Header from './components/Header';
import Services from './components/Services';
// import How from './components/review';
import Review from './components/review';
import Contact from './components/Contact';
import Gallery from './components/Gallery';
import SubFooter from './components/SubFooter';
import Footer from './components/Footer';


function App() {
  return (
    <div className="App">
      <Nav/>
      <Header/>
      <Services/>
      <Review/>
      <Contact/>
      <Gallery/>
      <SubFooter/>
      <Footer/>
    </div>
  );
}

export default App;
