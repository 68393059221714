import React from 'react'

function Footer() {
  return (
    <div className='bg-[#F6F6F6] pt-[32px] pb-[16px]'>
        <div className='flex pt-[30px] px-4 max-w-[1200px] mx-auto'>
            <div className='flex w-full flex-row justify-between items-center'>
                <div className='flex flex-col items-start'>
                    <div className='flex flex-row items-center gap-x-2 mb-[20px]'>
                        <img className='max-w-[120px]' src="/images/logo.png"/>
                    </div>
                    <p className='text-[16px] max-w-[340px] text-left text-black/60'>An instant photo station experience to help your event be amazing.</p>
                </div>
                <div className='flex flex-row items-end gap-x-4'>
                    <a href="https://www.facebook.com/EventPhotoStations?mibextid=LQQJ4d" target='_blank' ><img className='w-[32px]' src="/images/Facebook.png"/></a>
                    <a href="https://www.instagram.com/eventphotostations/" target='_blank'><img className='w-[24px]' src="/images/Original.png"/></a>
                </div>
            </div>
        </div>
        <div className='py-[12px] border-t mt-[12px] border-t-black/10'>
            <div className='max-w-[1200px] w-full mx-auto px-4'>
                <p className='text-[16px] text-black/70 text-left'>Event Photo Stations © 2024,  All Rights Reserved</p>
            </div>
        </div>
    </div>
  )
}

export default Footer