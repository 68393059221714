import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


function Gallery() {
  return (
    <div id="gallery" className="flex bg-[#F6F6F6] relative flex-col mx-auto items-center justify-center px-4 py-[62px]">
            <h6 className="text-black text-[40px] font-extrabold mb-[50px]">
                Our Gallery
                </h6>
                  <Carousel>
                    
                <div>
                    <img className='max-w-[600px] max-h-[430px] aspect-auto object-contain' src="./images/gallery/1.jpg" />
                </div>
                <div>
                    <img className='max-w-[600px] max-h-[430px] aspect-auto object-contain' src="./images/gallery/2.jpg" />
                </div>
                <div>
                    <img className='max-w-[600px] max-h-[430px] aspect-auto object-contain' src="./images/gallery/3.jpg" />
                </div>
                <div>
                    <img className='max-w-[600px] max-h-[430px] aspect-auto object-contain' src="./images/gallery/4.jpg" />
                </div>
                <div>
                    <img className='max-w-[600px] max-h-[430px] aspect-auto object-contain' src="./images/gallery/5.jpg" />
                </div>
                <div>
                    <img className='max-w-[600px] max-h-[430px] aspect-auto object-contain' src="./images/gallery/6.jpg" />
                </div>
                <div>
                    <img className='max-w-[600px] max-h-[430px] aspect-auto object-contain' src="./images/gallery/7.jpg" />
                </div>
                <div>
                    <img className='max-w-[600px] max-h-[430px] aspect-auto object-contain' src="./images/gallery/8.jpg" />
                </div>
                <div>
                    <img className='max-w-[600px] max-h-[430px] aspect-auto object-contain' src="./images/gallery/9.jpg" />
                </div>
                <div>
                    <img className='max-w-[600px] max-h-[430px] aspect-auto object-contain' src="./images/gallery/10.jpg" />
                </div>
                <div>
                    <img className='max-w-[600px] max-h-[430px] aspect-auto object-contain' src="./images/gallery/11.jpg" />
                </div>
                <div>
                    <img className='max-w-[600px] max-h-[430px] aspect-auto object-contain' src="./images/gallery/12.jpg" />
                </div>
                <div>
                    <img className='max-w-[600px] max-h-[430px] aspect-auto object-contain' src="./images/gallery/13.jpg" />
                </div>
                <div>
                    <img className='max-w-[600px] max-h-[430px] aspect-auto object-contain' src="./images/gallery/14.jpg" />
                </div>
                <div>
                    <img className='max-w-[600px] max-h-[430px] aspect-auto object-contain' src="./images/gallery/15.jpg" />
                </div>

            </Carousel>
   </div>
  )
}

export default Gallery