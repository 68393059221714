
"use client";

import { Button, Navbar } from "flowbite-react";

export function Nav() {
  return (
    <Navbar fluid rounded>
      <Navbar.Brand href="https://flowbite-react.com">
        <img src="/images/logo.png" className="mr-3 w-[96px]" alt="Flowbite React Logo" />
      </Navbar.Brand>
      <div className="flex md:order-2 list-none items-center">
        <a href="#contact">
          {/* <Button className="bg-[#4BB0E2] items-center flex flex-row gap-x-2"> <img className="max-w-[25px]" src="/images/reserve-icon.png"/>$19 Intro Class</Button> */}
          <a href="#contact" className='bg-[#4BB0E2] rounded-[8px] px-4 2xl:w-[191px] h-[49px] flex  items-center justify-center text-white flex flex-row gap-x-2 items-center'><img className='w-[25px]' src="/images/reserve-icon.png"/>Reserve Now</a>
          </a>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <Navbar.Link className="text-[19px] text-black" href="#what">What we offer</Navbar.Link>
        <Navbar.Link className="text-[19px] text-black" href="#gallery">Gallery</Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  );
}
