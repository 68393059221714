import React, { useState, useEffect } from 'react';
import Testimonials from './Testimonials';

function Header() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "/images/bg-1.png",
    "/images/bg-2.png",
    "/images/bg-3.png",
    "/images/bg-4.png",
    "/images/bg-5.png"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change the duration according to your preference

    return () => clearInterval(interval);
  }, []); // Empty dependency array to run this effect only once on component mount

  return (
    <>
      <div className='min-h-[55vh] relative bg-[#F2F3F7] bg-no-repeat bg-cover flex flex-col items-center justify-center'>
        <div className='flex  flex-col px-4 lg:pr-4 xl:flex-row-reverse justify-between pt-2 lg:pt-0 mx-auto ml-0'>
          <div className='flex relative z-10 flex-col mt-[15%] items-center lg:items-start space-y-[40px]'>
            <h1 className='text-[40px] lg:max-w-[530px] 2xl:text-[52px] text-black font-extrabold text-center lg:text-left leading-tight'>Be Sure Your Event Will Be <span className='text-[#4BB0E2]'>Memorable </span></h1>
            <p className='text-[16px] max-w-[500px] 2xl:text-[20px] text-black/70 font-medium text-center lg:text-left'>An instant photo station experience To help your event be amazing.</p>
            <div className='flex flex-col lg:flex-row items-center gap-[20px]'>
              <a href="#contact" className='bg-[#4BB0E2] rounded-[8px] px-4 2xl:w-[228px] h-[55px] flex items-center justify-center text-white flex flex-row gap-x-2 items-center'><img className='w-[25px]' src="/images/reserve-icon.png" alt="Reserve Icon" />Reserve Now</a>
              <a href="#contact" className='text-[19px] text-black/60 text-center lg:text-left'>Cincinnati, Dayton, Columbus, <br /> Indianapolis, Louisville, Lexington</a>
            </div>
          </div>
          <img className='w-full lg:max-w-[60%] mt-12 lg:mt-0 transition-opacity duration-1000 inline-block' src={images[currentImageIndex]} alt={`Background ${currentImageIndex + 1}`} />
        </div>
      </div>
      <Testimonials />
    </>
  );
}

export default Header;
