import React from 'react'

function Contact() {
  return (
    <div id="contact" className="flex flex-col mx-auto items-center justify-center px-4 py-[62px]">
    <h6 className="text-black text-[40px] font-extrabold mb-[50px]">
      Reserve Us Today!
    </h6>
    <form 
      action="https://formspree.io/f/xbjnvrlv"
      method="POST"
     className='gap-y-[28px] max-w-full flex flex-col w-full lg:w-auto'>
      <div className='flex flex-row gap-x-[40px]'>
        <input required name="date" className='bg-[#F7F7F7] w-full lg:w-[496px] h-[60px] rounded-[14px] border border-[#D8D8D8]' type="date" />
        <input required name="time" className='bg-[#F7F7F7] w-full lg:w-[496px] h-[60px] rounded-[14px] border border-[#D8D8D8]' type="time" />
      </div>
      <div className='w-full'>
      <select required
      className='bg-[#F7F7F7] w-full lg:w-full h-[60px] rounded-[14px] border border-[#D8D8D8]'
       name="Event Type" id="cars">
        <option value="Bar/Bat Mitzvah">Bar/Bat Mitzvah</option>
        <option value="Wedding">Wedding</option>
        <option value="Fundraiser">Fundraiser</option>
        <option value="Corporate Party">Corporate Party</option>
        <option value="School Event">School Event</option>
        <option value="Quinceanera">Quinceanera</option>
        <option value="Birthday Party">Birthday Party</option>
        <option value="Other">Other</option>
</select>
        </div>
      <div className='w-full'><input placeholder='Event Location' className=' bg-[#F7F7F7] w-full h-[60px] rounded-[14px] border border-[#D8D8D8]' type="text" /></div>
      <div className='flex flex-col gap-y-[28px]  lg:flex-row gap-x-[40px]'>
        <input required name="# Of Guest" placeholder='Estimated # of Guests' className='bg-[#F7F7F7] lg:w-[496px] h-[60px] rounded-[14px] border border-[#D8D8D8]' type="text" />
        <input required name='Full Name' placeholder='Full Name' className='bg-[#F7F7F7] lg:w-[496px] h-[60px] rounded-[14px] border border-[#D8D8D8]' type="text" />
      </div>
      <div className='flex flex-col gap-y-[28px]  lg:flex-row gap-x-[40px]'>
        <input required name='Email' placeholder='Email Address' className='bg-[#F7F7F7] lg:w-[496px] h-[60px] rounded-[14px] border border-[#D8D8D8]' type="email" />
        <input required name='Phone Number' placeholder='Phone Number' className='bg-[#F7F7F7] lg:w-[496px] pl-2 h-[60px] rounded-[14px] border border-[#D8D8D8]' type="phone" />
      </div>
      <button type='submit' className='bg-[#4BB0E2] rounded-[15px] text-[20px] font-semibold text-white w-full h-[60px]  flex flex-col items-center justify-center'>Submit Form</button>
    </form>
  </div>
  )
}

export default Contact