import React from "react";

function Services() {
  return (
    <div id="what" className="flex flex-col mx-auto items-center justify-center px-4 py-[62px]">
      <h6 className="text-black text-[40px] font-extrabold mb-[50px]">
      What We Offer
      </h6>
      <div className="flex flex-col gap-y-[40px] lg:flex-row items-start justify-center gap-x-[100px] mb-[50px]">
        <div className="flex flex-col items-start gap-y-[24px] max-w-[282px]">
          <img src="/images/roam.png" />
          <p className="text-[18px] text-black text-left">
          Our Roaming Photo Stations is a unique design for larger events. A photographer can capture a photo and it will be sent to another staff person who helps...
            <details>
              <summary className="text-[#4BB0E2]">Click For More</summary>

              <p> guests text their photo (with graphics added) within seconds! Perfect for sporting events, concerts and large private events.
</p>
            </details>
          </p>
        </div>
        <div className="flex flex-col items-start gap-y-[24px] max-w-[282px]">
          <img src="/images/live.png" />
          <p className="text-[18px] text-black text-left">
          With all of our different set up options, we offer a live Slideshow of the photos. Photos from the Photo Station..

            <details>
              <summary className="text-[#4BB0E2]">Click For More</summary>

              <p> or Roaming photographer immediately appear on a screen or network of TVs that your venue has and is placed in a rotating slideshow of the 10 newest photos. This allows for a fun photo experience for everyone at your event as they can watch others having photo fun!
</p>
            </details>
          </p>
        </div>
        <div className="flex flex-col items-start gap-y-[24px] max-w-[282px]">
          <img src="/images/photos.png" />
          <p className="text-[18px] text-black text-left">
          Our event photo stations are staffed with a live photographer, which allows for more creative photos, larger groups and less wait time.
            <details>
              <summary className="text-[#4BB0E2]">Click For More</summary>

              <p>We custom pre-design graphics for the photos with the client to make the event that more memorable. At the end of the event, the host will receive a USB Flash drive of all the photos. We can have a greenscreen set up for digital backgrounds or take photos in front of your own design. Packages offered are: digital only, photo printing, sticker printing and magnet printing</p>
            </details>
          </p>
        </div>
      </div>
      <div className='flex flex-row items-center gap-x-[20px]'>
      <a href="#contact" className='bg-[#4BB0E2] rounded-[8px] px-4 2xl:w-[228px] h-[55px] flex  items-center justify-center text-white flex flex-row gap-x-2 items-center'><img className='w-[25px]' src="/images/reserve-icon.png"/>Reserve Now</a>
      </div>
    </div>
  );
}

export default Services;
